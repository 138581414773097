import React from 'react';

const Main = React.lazy(() => import('./Main'));
const Create = React.lazy(() => import('./Create'));

export const fclPagesConfigs = [
  {
    path: '/fcl-price',
    element: <Main />,
  },
  {
    path: '/fcl-price/create/:termId',
    element: <Create />,
  },
];
