/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import AppSidebar from './AppSidebar';
import AppContentView from '@crema/core/AppContentView';
import AppHeader from './AppHeader';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import MiniSidebarToggleWrapper from './MiniSidebarToggleWrapper';
import AppFixedFooter from './AppFixedFooter';
import {useLayoutContext} from '../../../utility/AppContextProvider/LayoutContextProvider';
import {LayoutType} from 'shared/constants/AppEnums';
import MiniSidebarToggleContainer from './MiniSidebarToggleContainer';
import {useAuthUser} from '@crema/utility/AuthHooks';
import {useLocation, useNavigate} from 'react-router-dom';
import {isSubPath} from 'utils/isSubPath';

const MiniSidebarToggle = () => {
  const {user} = useAuthUser();
  const {pathname} = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    let findMenu = {};
    user.menus?.forEach((menu) => {
      if (menu.children?.length) {
        menu.children?.forEach((child) => {
          if (isSubPath(pathname, child.url)) {
            findMenu = child;

            return;
          }
        });

        return;
      } else {
        if (isSubPath(pathname, menu.url)) {
          findMenu = menu;

          return;
        }
      }
    });

    if (findMenu) {
      if (
        pathname.toLowerCase().includes('/create') &&
        !findMenu?.permission?.create
      ) {
        navigate(-1);
      } else if (
        pathname.toLowerCase().includes('/edit') &&
        !findMenu?.permission?.update
      ) {
        navigate(-1);
      } else if (
        pathname.toLowerCase().includes('/detail') &&
        !findMenu?.permission?.read
      ) {
        navigate(-1);
      } else if (
        pathname.toLowerCase() === findMenu.url &&
        !findMenu.permission?.read
      ) {
        navigate(-1);
      }
    } else {
      navigate(-1);
    }
  }, [user, pathname]);

  const [isCollapsed, setCollapsed] = useState(false);
  const {footer, layoutType, headerType, footerType} = useLayoutContext();

  return (
    <MiniSidebarToggleContainer
      className={clsx({
        boxedLayout: layoutType === LayoutType.BOXED,
        framedLayout: layoutType === LayoutType.FRAMED,
      })}
    >
      <MiniSidebarToggleWrapper
        className={clsx('miniSidebarToggleWrapper', {
          'mini-sidebar-collapsed': isCollapsed,
          appMainFooter: footer && footerType === 'fluid',
          appMainFixedFooter: footer && footerType === 'fixed',
          appMainFixedHeader: headerType === 'fixed',
        })}
      >
        <AppSidebar />
        <Box className='mainContent'>
          <AppHeader setCollapsed={setCollapsed} isCollapsed={isCollapsed} />
          <AppContentView />
          <AppFixedFooter />
        </Box>
      </MiniSidebarToggleWrapper>
    </MiniSidebarToggleContainer>
  );
};

export default MiniSidebarToggle;
