import * as React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import LinearProgress from '@mui/material/LinearProgress';
import InputLabel from '@mui/material/InputLabel';

export const BasicAutocomplete = ({
  label,
  required = false,
  error = false,
  errorText,
  disabled,
  value,
  placeholder = '',
  isLoading = false,
  options = [],
  onInputChange = () => {},
  onChange = () => {},
  // onBlur = () => {},
  // inputValue,
  freeSolo,
}) => {
  return (
    <>
      <InputLabel required={required}>{label}</InputLabel>
      <FormControl disabled={disabled} error={error} fullWidth>
        {isLoading && <LinearProgress sx={{mx: 2}} />}
        <Autocomplete
          freeSolo={freeSolo}
          placeholder={placeholder}
          options={options}
          fullWidth
          value={value}
          onChange={(e, value) => onChange(e, value)}
          noOptionsText='No data'
          isOptionEqualToValue={(option, value) =>
            option?.label === value?.label
          }
          getOptionLabel={(option) => option.label || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
          onInputChange={(e, valueInput) => {
            onInputChange(valueInput);
          }}
        />
        {error && !!errorText && <FormHelperText>{errorText}</FormHelperText>}
      </FormControl>
    </>
  );
};

BasicAutocomplete.propTypes = {
  minWidth: PropTypes.any,
  options: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  inputValue: PropTypes.string,
  freeSolo: PropTypes.bool,
  isLoading: PropTypes.bool,
  onInputChange: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default BasicAutocomplete;
