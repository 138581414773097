/* eslint-disable react/prop-types */
import React, {createContext, useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import jwtAxios, {setAuthToken} from './index';
import {
  fetchError,
  fetchStart,
  fetchSuccess,
  showMessage,
} from '../../../../redux/actions';
import {useLogin, useChangeProfile, getMe} from 'hooks/Auth';
import {setAuthHeader} from 'services/axios';
// import secureLocalStorage from 'react-secure-storage';
import routesConfig from '../../../../pages/routesConfig';
import convertMenus from 'utils/convertMenus';
// import {useNavigate} from 'react-router-dom';
import {MdOutlineSpaceDashboard} from 'react-icons/md';

let listMenu = [];
routesConfig.forEach((item) => {
  if (item.children) {
    item.children.forEach((child) =>
      listMenu.push({title: child.title, url: child.url}),
    );

    return;
  }

  listMenu.push({title: item.title, url: item.url});
});

const JWTAuthContext = createContext({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});

const JWTAuthActionsContext = createContext({
  signUpUser: () => {},
  signInUser: () => {},
  updateUser: () => {},
  logout: () => {},
});

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({children}) => {
  // const navigate = useNavigate();
  const [authData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const {mutate} = useLogin();
  const {mutate: mutateUpdateProfile} = useChangeProfile();

  const dispatch = useDispatch();

  useEffect(() => {
    const getAuthUser = async () => {
      const token = localStorage.getItem('token');

      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      setAuthToken(token);
      setAuthHeader(token);
      const admin = JSON.parse(localStorage.getItem('user'));

      setJWTAuthData({
        user: {
          ...admin,
          displayName: admin.name,
          // photoURL: admin.profile,
          // role,
          menus:
            admin.menus
              ?.filter((menu) => menu.permission.read || menu?.children?.length)
              ?.map((menu) => ({
                ...menu,
                icon: <MdOutlineSpaceDashboard />,
                children: menu?.children
                  ?.filter(
                    (child) => child.permission.read || menu?.children?.length,
                  )
                  ?.map((child) => ({
                    ...child,
                    icon: <MdOutlineSpaceDashboard />,
                  })),
              })) || [],
        },
        isAuthenticated: true,
        isLoading: false,
      });
    };

    getAuthUser();
  }, []);

  const signInUser = async ({email, password}) => {
    dispatch(fetchStart());
    const formData = {email, password};
    mutate(formData, {
      onSuccess: async (res) => {
        const {token, refresh_token} = res.data.key;
        const {admin, menus} = res.data;

        if (!menus?.length) {
          return dispatch(fetchError('Sorry, You do not have any access.'));
        }

        localStorage.setItem('token', token);
        localStorage.setItem('refresh_token', refresh_token);
        setAuthToken(token);
        setAuthHeader(token);
        const is_approver = admin.role.is_approver;
        const permissions = [];
        convertMenus(menus || [])?.forEach((menu) => {
          if (menu.children?.length) {
            menu.children?.forEach((childMenu) => permissions.push(childMenu));
          } else {
            permissions.push(menu);
          }
        });

        localStorage.setItem(
          'user',
          JSON.stringify({
            ...admin,
            is_approver,
            menus: convertMenus(menus || []),
            permissions,
            initialUrl: convertMenus(menus || [])[0].url,
          }),
        );
        setJWTAuthData({
          user: {
            ...admin,
            displayName: admin.name,
            is_approver,
            // photoURL: admin.profile,
            // role,
            menus: convertMenus(menus || []),
            permissions,
            initialUrl: convertMenus(menus || [])[0].url,
          },
          isAuthenticated: true,
          isLoading: false,
        });
        dispatch(fetchSuccess());
      },
      onError: (res) => {
        console.log(res);
        setJWTAuthData({
          ...authData,
          isAuthenticated: false,
          isLoading: false,
        });
        dispatch(
          fetchError(res.response?.data?.message || 'Something went wrong'),
        );
      },
    });
  };

  const updateUser = async (formValue) => {
    mutateUpdateProfile(
      {
        name: formValue.displayName,
        email: formValue.email,
        phone_number: formValue.phone_number,
      },
      {
        onSuccess: () => {
          dispatch(showMessage('Change Profile Successfully'));
          getMe().then((res) => {
            const newData = res.data;
            const is_approver = newData.role.is_approver;
            delete newData.role;
            localStorage.setItem(
              'user',
              JSON.stringify({
                ...newData,
                displayName: newData?.name,
                is_approver,
              }),
            );
            setJWTAuthData({
              user: {
                ...newData,
                displayName: newData?.name,
                is_approver,
              },
              isAuthenticated: true,
              isLoading: false,
            });
          });
        },
        onError: (res) => {
          console.log(res);
          dispatch(
            fetchError(res?.response?.data?.errorMessage || 'Something Error'),
          );
        },
      },
    );
  };

  const signUpUser = async ({name, email, password}) => {
    dispatch(fetchStart());
    try {
      const {data} = await jwtAxios.post('users', {name, email, password});
      localStorage.setItem('token', data.token);
      setAuthToken(data.token);
      const res = await jwtAxios.get('/auth');
      setJWTAuthData({
        user: res.data,
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch(fetchSuccess());
    } catch (error) {
      setJWTAuthData({
        ...authData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch(fetchError('Something went wrong'));
    }
  };

  const logout = async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...authData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
          updateUser,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;
