export default function convertToPhone(angka) {
  if (angka) {
    let number_string = angka
      .toString()
      .replace(/[^,\d]/g, '')
      .toString();

    // Mengambil bagian-bagian nomor telepon
    const areaCode = number_string.slice(0, 4);
    const middlePart = number_string.slice(4, 8);
    const lastPart = number_string.slice(8);

    // Menggabungkan bagian-bagian tersebut dalam format yang diinginkan
    const nomorTerformat = `${areaCode}${middlePart && '-'}${middlePart}${
      lastPart && '-'
    }${lastPart}`;

    return nomorTerformat;
  } else return angka;
}
