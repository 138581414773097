import React from 'react';

const List = React.lazy(() => import('./List'));
const Create = React.lazy(() => import('./Create'));
const Detail = React.lazy(() => import('./Detail'));
const Edit = React.lazy(() => import('./Edit'));

export const vendorShipManagementPagesConfigs = [
  {
    path: '/vendor-ship-management',
    element: <List />,
  },
  {
    path: '/vendor-ship-management/create',
    element: <Create />,
  },
  {
    path: '/vendor-ship-management/:id',
    element: <Detail />,
  },
  {
    path: '/vendor-ship-management/:id/edit',
    element: <Edit />,
  },
];
