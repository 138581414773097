// The environment to use when building the project
const CONFIG = {
  endpoint: {
    APP_ENDPOINT: process.env.REACT_APP_URL,
    API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
    PUSHER_KEY: process.env.REACT_APP_PUSHER_KEY,
    PUSHER_CLUSTER: process.env.REACT_APP_PUSHER_CLUSTER,
  },
};

export default CONFIG;
