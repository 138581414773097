import React from 'react';

const List = React.lazy(() => import('./List'));
const Edit = React.lazy(() => import('./Edit'));

export const shippingPricePagesConfigs = [
  {
    path: '/shipping-price',
    element: <List />,
  },
  {
    path: '/shipping-price/:id/edit',
    element: <Edit />,
  },
];
