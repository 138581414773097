/* eslint-disable no-unused-vars */
import React from 'react';
import {useAuthUser} from '@crema/utility/AuthHooks';

export const usePermission = (messageId) => {
  const {user} = useAuthUser();

  const findPemission = user.permissions?.find(
    (menu) => menu.messageId === messageId,
  );

  return (
    findPemission?.permission || {
      create: false,
      read: false,
      update: false,
      delete: false,
      approval: false,
    }
  );
};
