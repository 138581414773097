import {useState} from 'react';
import {useQuery} from 'react-query';
import axios from 'services/axios';

export const QUERY = 'auth-user';

export async function getMe(filter) {
  const {data} = await axios.get(`api/v1/admin/auth/profile`, {
    params: {...filter},
  });

  return data;
}

export function useMe() {
  const [filter, setFilter] = useState({
    page: 1,
    search: null,
    sortBy: 'desc',
    sortOrder: 'created_at',
  });
  const fallback = [];
  const {
    data = fallback,
    isLoading,
    isError,
    error,
  } = useQuery([QUERY, filter], async () => getMe(filter));

  return {
    data,
    isLoading,
    isError,
    error,
    filter,
    setFilter,
  };
}

export const fetchAdminManagement = (adminManagementId) => {
  return axios
    .get(`api/v1/admin/admin-management/${adminManagementId}`)
    .then((res) => res.data);
};

export function useDetailAdminManagement(adminManagementId) {
  return useQuery({
    queryKey: adminManagementId && [QUERY, adminManagementId],
    queryFn: () => fetchAdminManagement(adminManagementId),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!adminManagementId,
  });
}
