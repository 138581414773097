import {
  SET_FILTER_HPP_FCL,
  RESET_FILTER_HPP_FCL,
} from 'shared/constants/ActionTypes';

const INIT_STATE = {
  page: 1,
  search: null,
  limit: 10,
  sortBy: 'desc',
  sortOrder: 'created_at',
};

const filterHppFclReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_FILTER_HPP_FCL: {
      return {...state, ...action.payload};
    }
    case RESET_FILTER_HPP_FCL: {
      return {
        page: 1,
        search: null,
        limit: 10,
        sortBy: 'desc',
        sortOrder: 'created_at',
      };
    }
    default:
      return state;
  }
};
export default filterHppFclReducer;
