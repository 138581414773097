import axios from 'services/axios';
import {useMutation, useQueryClient} from 'react-query';
import {QUERY} from './useQuery';

export function useReadNotification() {
  const queryClient = useQueryClient();

  return useMutation(
    (id) =>
      axios.put(`api/v1/admin/notification/${id}`, {}).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useReadAllNotification() {
  const queryClient = useQueryClient();

  return useMutation(
    () =>
      axios
        .put(`api/v1/admin/notification/read-all`, {})
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}
