import React from 'react';
import {Navigate} from 'react-router-dom';
import {useAuthUser} from '@crema/utility/AuthHooks';

const InitPage = () => {
  const {user} = useAuthUser();

  return <Navigate to={user?.initialUrl || '/signin'} />;
};

export default InitPage;
