/* eslint-disable no-unused-vars */
import axios from 'axios';
import CONFIG from 'config/env';
import {setAuthToken} from '@crema/services/auth/jwt-auth';

const BASE_URL = CONFIG.endpoint.API_ENDPOINT;
let instance = null;

function makeInstance() {
  if (instance) {
    return instance;
  }

  instance = axios.create({
    baseURL: BASE_URL,
    timeout: 60000,
  });

  instance.interceptors.response.use(
    (res) => res,
    (error) => {
      if (error.response.status === 401) {
        const refresh_token = localStorage.getItem('refresh_token');
        instance
          .post('/auth/refresh_token', {
            refresh_token,
          })
          .then(
            (response) => {
              const {token, refresh_token: new_refresh_token} =
                response.data.data.key;
              localStorage.setItem('token', token);
              localStorage.setItem('refresh_token', new_refresh_token);
              setAuthToken(token);
              setAuthHeader(token);
            },
            (err) => {
              console.log('err :>> ', err);
              localStorage.removeItem('token');
              localStorage.removeItem('refresh_token');
              localStorage.removeItem('user');
              window.location.replace('/');
            },
          )
          .catch(() => {
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            window.location.replace('/');
          });
      } else {
        return Promise.reject(error);
      }
    },
  );

  return instance;
}

const axiosInstance = makeInstance();

export const setAuthHeader = (accessToken) => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export const resetAuthHeader = () => {
  delete axiosInstance.defaults.headers.common.Authorization;
};

export default axiosInstance;
