import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import {DatePicker as MUIDatePicker} from '@mui/x-date-pickers';
import {Stack} from '@mui/material';
import {parseISO} from 'date-fns';

export const DatePicker = ({
  maxWidth = '100%',
  label,
  required = false,
  error = false,
  errorText,
  disabled,
  value,
  // defaultValue = '',
  name,
  minDate,
  maxDate,
  onChange = () => {},
  onBlur = () => {},
}) => {
  return (
    <Stack>
      <InputLabel required={required}>{label}</InputLabel>
      <FormControl disabled={disabled} error={error} sx={{maxWidth}}>
        <MUIDatePicker
          value={value}
          // defaultValue={defaultValue}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          format='dd-MM-yyyy'
          inputFormat='dd-MM-yyyy'
          slotProps={{textField: {variant: 'outlined'}}}
          minDate={parseISO(minDate)}
          maxDate={parseISO(maxDate)}
        />
        {error && !!errorText && <FormHelperText>{errorText}</FormHelperText>}
      </FormControl>
    </Stack>
  );
};

DatePicker.propTypes = {
  maxWidth: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
};

export default DatePicker;
