import React from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogTitle, Slide} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {Fonts} from 'shared/constants/AppEnums';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const Transition = React.forwardRef(function Transition(
  props,
  // eslint-disable-next-line no-undef
  ref,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const BasicDialog = ({
  sxStyle,
  maxWidth = 'sm',
  isOpenDialog,
  children,
  actionChildren,
  title,
  onClose = () => {},
}) => {
  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
        },
        '& .MuiDialogContent-root': {
          overflowY: 'auto',
          paddingLeft: 0,
          paddingRight: 0,
        },
        ...sxStyle,
      }}
      maxWidth={maxWidth}
      TransitionComponent={Transition}
      open={isOpenDialog}
      onClose={onClose}
    >
      <DialogTitle
        sx={{
          fontSize: 14,
          fontWeight: Fonts.MEDIUM,
          background: '#F3FDFF',
          color: '#2155CD',
          textTransform: 'uppercase',
        }}
        id='app-dialog-title'
      >
        {title}
        <IconButton
          aria-label='close'
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: 'grey.500',
          }}
          size='large'
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{px: 6, py: 6}}>{children}</Box>
      </DialogContent>
      <DialogActions>
        <Stack spacing={4} direction='row'>
          {actionChildren}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

BasicDialog.propTypes = {
  sxStyle: PropTypes.any,
  maxWidth: PropTypes.any,
  isOpenDialog: PropTypes.bool.isRequired,
  children: PropTypes.element,
  actionChildren: PropTypes.element,
  title: PropTypes.string,
  onClose: PropTypes.func,
};

export default BasicDialog;
