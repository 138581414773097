export default function convertToOptions(
  data,
  valueKey = 'id',
  labelKey = 'name',
) {
  const options = data.map((item) => {
    return {
      value: item[valueKey],
      label: item[labelKey],
    };
  });

  return options;
}
