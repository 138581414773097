import React from 'react';
// import notification from '@crema/services/db/notifications';
import {IconButton, Grid, Stack, Button} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import AppScrollbar from '@crema/core/AppScrollbar';
import IntlMessages from '@crema/utility/IntlMessages';
import NotificationItem from './NotificationItem';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useReadNotification, useReadAllNotification} from 'hooks/Notification';
import {BasicDialog} from 'components/Dialog';
import {useNavigate} from 'react-router-dom';

const AppNotificationContent = ({
  onClose,
  sxStyle,
  notification = [],
  canLoadMore = false,
  onLoadMore = () => {},
}) => {
  const navigate = useNavigate();
  const [isOpenNotif, setIsOpenNotif] = React.useState(false);
  const [notifData, setNotifData] = React.useState({});
  const {mutate: mutateRead} = useReadNotification();
  const {mutate: mutateReadAll} = useReadAllNotification();

  const handleRead = (id) => {
    mutateRead(id, {});
  };

  const handleReadAll = (id) => {
    mutateReadAll(id, {});
  };

  const handleDetail = (id) => {
    setIsOpenNotif(false);
    onClose();
    navigate(`/order-management/${id}/detail`);
  };

  return (
    <>
      <BasicDialog
        title={notifData.name}
        isOpenDialog={isOpenNotif}
        onClose={() => setIsOpenNotif(false)}
      >
        <Grid container spacing={8}>
          <Grid item sm={12}>
            <Typography>{notifData.message}</Typography>
          </Grid>
          {!!notifData?.data?.order_id && (
            <Grid item sm={12}>
              <Stack direction='row' spacing={4} justifyContent='flex-end'>
                <Button
                  variant='contained'
                  onClick={() => handleDetail(notifData?.data?.order_id)}
                >
                  Lihat Detail Order
                </Button>
              </Stack>
            </Grid>
          )}
        </Grid>
      </BasicDialog>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 280,
          height: '100%',
          ...sxStyle,
        }}
      >
        <Box
          sx={{
            padding: '5px 20px',
            display: 'flex',
            alignItems: 'center',
            borderBottom: 1,
            borderBottomColor: (theme) => theme.palette.divider,
            minHeight: {xs: 56, sm: 70},
          }}
        >
          <Typography component='h3' variant='h3'>
            <IntlMessages id='common.notifications' />({notification.length})
          </Typography>
          <IconButton
            sx={{
              height: 40,
              width: 40,
              marginLeft: 'auto',
              color: 'text.secondary',
            }}
            onClick={onClose}
            size='large'
          >
            <CancelOutlinedIcon />
          </IconButton>
        </Box>
        <AppScrollbar
          sx={{
            height: {xs: 'calc(100% - 96px)', sm: 'calc(100% - 110px)'},
          }}
        >
          <List sx={{py: 0}}>
            {notification.map((item) => (
              <NotificationItem
                key={item.id}
                item={item}
                onRead={() => {
                  handleRead(item.id);
                  setIsOpenNotif(true);
                  setNotifData(item);
                }}
              />
            ))}
            {canLoadMore && (
              <ListItem
                sx={{
                  padding: '8px 20px',
                  borderBottom: 1,
                  borderColor: '#F0F0F0',
                }}
              >
                <Stack
                  sx={{minWidth: '100%'}}
                  direction='row'
                  justifyContent='center'
                >
                  <Button onClick={onLoadMore}>Load More</Button>
                </Stack>
              </ListItem>
            )}
          </List>
        </AppScrollbar>
        <Button
          sx={{
            borderRadius: 0,
            width: '100%',
            textTransform: 'capitalize',
            marginTop: 'auto',
            height: 40,
          }}
          variant='contained'
          color='primary'
          onClick={handleReadAll}
        >
          Read All
        </Button>
      </Box>
    </>
  );
};

export default AppNotificationContent;

AppNotificationContent.propTypes = {
  onClose: PropTypes.func,
  onLoadMore: PropTypes.func,
  sxStyle: PropTypes.object,
  notification: PropTypes.array,
  canLoadMore: PropTypes.bool,
};
