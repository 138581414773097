import {MdOutlineSpaceDashboard} from 'react-icons/md';

const convertMenus = (menus) => {
  return (
    menus
      ?.filter((menu) => menu.permission.read || menu?.childs?.length)
      ?.map((menu) => ({
        id: menu.id,
        title: menu.name,
        messageId: menu.name?.toLowerCase().replaceAll(' ', '.'),
        type: menu.childs?.length ? 'collapse' : 'item',
        icon: <MdOutlineSpaceDashboard />,
        url: menu.childs?.length ? undefined : menu.link,
        permission: menu.permission,
        children: convertMenus(menu.childs),
      })) || []
  );
};

export default convertMenus;
