/* eslint-disable no-unused-vars */
import * as React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import {useDistrict} from 'hooks/Misc';
import convertToOptions from 'utils/convertToOptions';
import LinearProgress from '@mui/material/LinearProgress';
import InputLabel from '@mui/material/InputLabel';

export const AutocompleteDistrict = ({
  label,
  required = false,
  error = false,
  errorText,
  disabled,
  value,
  placeholder = '',
  cityId = '',
  alwaysEnabled = false,
  valueKey = 'id',
  labelKey = 'name',
  onChange = () => {},
  // onBlur = () => {},
}) => {
  const {data, filter, setFilter, isLoading} = useDistrict(
    {city_id: cityId},
    alwaysEnabled,
  );
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if (data?.status) {
      // setOptions(convertToOptions(data?.data?.rows, valueKey, labelKey));
      setOptions(
        data?.data?.rows?.map((item) => ({
          value: item.id,
          label: item.name,
          city: item.city,
        })),
      );
    }
  }, [data]);

  React.useEffect(() => {
    if (!filter.search) setOptions([]);
  }, [filter]);

  React.useEffect(() => {
    if (!options.length && value) {
      setOptions([value]);
    }
  }, [options, value]);

  return (
    <>
      <InputLabel required={required}>{label}</InputLabel>
      <FormControl disabled={disabled} error={error} fullWidth value={value}>
        {isLoading && <LinearProgress sx={{mx: 2}} />}
        <Autocomplete
          placeholder={placeholder}
          fullWidth
          value={value}
          options={options}
          onChange={(e, selected) =>
            onChange(
              e,
              {value: selected.value, label: selected.label},
              selected.city,
            )
          }
          noOptionsText='No data'
          isOptionEqualToValue={(option, value) =>
            option?.label === value?.label
          }
          getOptionLabel={(option) => option.label || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
              }}
              placeholder={placeholder || 'Ketikan nama daerah...'}
            />
          )}
          onInputChange={(e, valueInput) => {
            setFilter({
              ...filter,
              search: valueInput,
            });
          }}
        />
        {error && !!errorText && <FormHelperText>{errorText}</FormHelperText>}
      </FormControl>
    </>
  );
};

AutocompleteDistrict.propTypes = {
  minWidth: PropTypes.any,
  options: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  cityId: PropTypes.string,
  alwaysEnabled: PropTypes.bool,
};

export default AutocompleteDistrict;
