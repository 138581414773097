import React from 'react';

const List = React.lazy(() => import('./List'));
const Create = React.lazy(() => import('./Create'));
const Edit = React.lazy(() => import('./Edit'));

export const adminManagementPagesConfigs = [
  {
    path: '/admin',
    element: <List />,
  },
  {
    path: '/admin/create',
    element: <Create />,
  },
  {
    path: '/admin/:id/edit',
    element: <Edit />,
  },
];
