import React from 'react';

const List = React.lazy(() => import('./List'));
const Create = React.lazy(() => import('./Create'));

export const goodsReceiptPagesConfigs = [
  {
    path: '/goods-receipt',
    element: <List />,
  },
  {
    path: '/goods-receipt/create',
    element: <Create />,
  },
];
