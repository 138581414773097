import {MdOutlineSpaceDashboard} from 'react-icons/md';

const routesConfig = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    messageId: 'dashboard',
    type: 'item',
    icon: <MdOutlineSpaceDashboard />,
    url: '/dashboard',
  },
  {
    id: 'role-management',
    title: 'Role Management',
    messageId: 'role.management',
    type: 'item',
    icon: <MdOutlineSpaceDashboard />,
    url: '/role',
  },
  {
    id: 'admin-management',
    title: 'Admin Management',
    messageId: 'admin.management',
    type: 'item',
    icon: <MdOutlineSpaceDashboard />,
    url: '/admin',
  },
  {
    id: 'shipping-management',
    title: 'Shipping Management',
    messageId: 'shipping.management',
    type: 'collapse',
    icon: <MdOutlineSpaceDashboard />,
    children: [
      {
        id: 'shipping-schedule',
        title: 'Jadwal Kapal',
        messageId: 'shipping-schedule',
        type: 'item',
        url: '/shipping-management',
      },
      {
        id: 'ship-departure',
        title: 'List Kapal Berangkat',
        messageId: 'ship-departure',
        type: 'item',
        url: '/ship-departure',
      },
      {
        id: 'goods-receipt',
        title: 'Penerimaan Barang LCL',
        messageId: 'goods-receipt',
        type: 'item',
        url: '/goods-receipt',
      },
    ],
  },
  {
    id: 'customer-management',
    title: 'Customer Management',
    messageId: 'customer.management',
    type: 'item',
    icon: <MdOutlineSpaceDashboard />,
    url: '/customer-management',
  },
  {
    id: 'order-management',
    title: 'Order Management',
    messageId: 'order.management',
    type: 'item',
    icon: <MdOutlineSpaceDashboard />,
    url: '/order-management',
  },
  {
    id: 'ttb',
    title: 'List TTB',
    messageId: 'ttb',
    type: 'item',
    icon: <MdOutlineSpaceDashboard />,
    url: '/ttb',
  },
  {
    id: 'master-data',
    title: 'Master Data',
    messageId: 'master.data',
    type: 'collapse',
    icon: <MdOutlineSpaceDashboard />,
    children: [
      {
        id: 'commodity-management',
        title: 'Commodity Management',
        messageId: 'commodity.management',
        type: 'item',
        url: '/commodity-management',
      },
      {
        id: 'vendor-trucking-management',
        title: 'Vendor Trucking Management',
        messageId: 'vendor.trucking.management',
        type: 'item',
        url: '/vendor-trucking-management',
      },
      {
        id: 'vendor-ship-management',
        title: 'Vendor Ship Management',
        messageId: 'vendor.ship.management',
        type: 'item',
        url: '/vendor-ship-management',
      },
      {
        id: 'route-management',
        title: 'Route Management',
        messageId: 'route.management',
        type: 'item',
        url: '/route-management',
      },
      {
        id: 'hpp-term',
        title: 'HPP Term',
        messageId: 'hpp.term',
        type: 'item',
        url: '/hpp-term',
      },
    ],
  },
  {
    id: 'pricing-management',
    title: 'Pricing Management',
    messageId: 'pricing.management',
    type: 'collapse',
    icon: <MdOutlineSpaceDashboard />,
    children: [
      {
        id: 'port-price',
        title: 'Master Harga Pelabuhan',
        messageId: 'port.price',
        type: 'item',
        url: '/port-price',
      },
      {
        id: 'shipping-price',
        title: 'Master Harga Pelayaran',
        messageId: 'shipping.price',
        type: 'item',
        url: '/shipping-price',
      },
      {
        id: 'other-price',
        title: 'Master Harga Lainnya',
        messageId: 'other.price',
        type: 'item',
        url: '/other-price',
      },
      {
        id: 'fcl-price',
        title: 'Komposisi HPP FCL',
        messageId: 'fcl.price',
        type: 'item',
        url: '/fcl-price',
      },
      {
        id: 'lcl-price',
        title: 'Komposisi HPP LCL',
        messageId: 'lcl.price',
        type: 'item',
        url: '/lcl-price',
      },
    ],
  },
  {
    id: 'finance-management',
    title: 'Finance Management',
    messageId: 'finance.management',
    type: 'collapse',
    icon: <MdOutlineSpaceDashboard />,
    children: [
      {
        id: 'receivables-report',
        title: 'Laporan Piutang',
        messageId: 'receivables.report',
        type: 'item',
        url: '/receivables-report',
      },
      {
        id: 'billing-report',
        title: 'Laporan Penagihan',
        messageId: 'billing.report',
        type: 'item',
        url: '/billing-report',
      },
    ],
  },
  {
    id: 'claim-management',
    title: 'Claim Management',
    messageId: 'claim.management',
    type: 'item',
    icon: <MdOutlineSpaceDashboard />,
    url: '/claim-management',
  },
  {
    id: 'ticket',
    title: 'Ticket',
    messageId: 'ticket',
    type: 'item',
    icon: <MdOutlineSpaceDashboard />,
    url: '/ticket',
  },
];
export default routesConfig;
