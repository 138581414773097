import {useState} from 'react';
import {useQuery} from 'react-query';
import axios from 'services/axios';

const QUERY_COUNTRY = 'countries';

async function getCountries(filter) {
  const {data} = await axios.get(`api/v1/admin/country`, {
    params: {...filter},
  });

  return data;
}

export function useCountry() {
  const [filter, setFilter] = useState({
    sortBy: 'desc',
    sortOrder: 'name',
    limit: 10,
    search: '',
    page: 1,
  });
  const fallback = [];
  const {
    data = fallback,
    isLoading,
    isError,
    error,
  } = useQuery([QUERY_COUNTRY, filter], async () => getCountries(filter), {
    enabled: !!filter.search,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    filter,
    setFilter,
  };
}

const QUERY_CITY = 'cities';

async function getCities(filter) {
  const {data} = await axios.get(`api/v1/admin/city`, {
    params: {...filter},
  });

  return data;
}

export function useCity(defaultFilter = {}) {
  const [filter, setFilter] = useState({
    sortBy: 'desc',
    sortOrder: 'name',
    limit: 10,
    search: '',
    page: 1,
  });
  const fallback = [];
  const {
    data = fallback,
    isLoading,
    isError,
    error,
  } = useQuery(
    [QUERY_CITY, filter, defaultFilter],
    async () => getCities({...filter, ...defaultFilter}),
    {
      enabled: !!filter.search || !!defaultFilter.search,
    },
  );

  return {
    data,
    isLoading,
    isError,
    error,
    filter,
    setFilter,
  };
}

const QUERY_PROVINCE = 'provincies';

async function getpProvinces(filter) {
  const {data} = await axios.get(`api/v1/admin/state`, {
    params: {...filter},
  });

  return data;
}

export function useProvince() {
  const [filter, setFilter] = useState({
    sortBy: 'desc',
    sortOrder: 'name',
    limit: 10,
    search: '',
    page: 1,
  });
  const fallback = [];
  const {
    data = fallback,
    isLoading,
    isError,
    error,
  } = useQuery([QUERY_PROVINCE, filter], async () => getpProvinces(filter), {
    enabled: !!filter.search,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    filter,
    setFilter,
  };
}

const QUERY_DISTRICT = 'districts';

async function getDistricts(filter) {
  const {data} = await axios.get(`api/v1/admin/district`, {
    params: {...filter},
  });

  return data;
}

export function useDistrict(defaultFilter = {}, alwaysEnabled = false) {
  const [filter, setFilter] = useState({
    sortBy: 'desc',
    sortOrder: 'name',
    limit: 10,
    search: '',
    page: 1,
  });
  const fallback = [];
  const {
    data = fallback,
    isLoading,
    isError,
    error,
  } = useQuery(
    [QUERY_DISTRICT, filter, defaultFilter],
    async () => getDistricts({...filter, ...defaultFilter}),
    {
      enabled: alwaysEnabled
        ? !!filter.search || !!defaultFilter.search
        : !!defaultFilter.city_id,
    },
  );

  return {
    data,
    isLoading,
    isError,
    error,
    filter,
    setFilter,
  };
}

const QUERY_BIG_CITY = 'big_cities';

async function getBigCities(filter) {
  const {data} = await axios.get(`api/v1/admin/big_city`, {
    params: {...filter},
  });

  return data;
}

export function useBigCity(defaultFilter = {}) {
  const [filter, setFilter] = useState({
    sortBy: 'desc',
    sortOrder: 'name',
    limit: 10,
    search: '',
    page: 1,
  });
  const fallback = [];
  const {
    data = fallback,
    isLoading,
    isError,
    error,
  } = useQuery(
    [QUERY_BIG_CITY, filter, defaultFilter],
    async () => getBigCities({...filter, ...defaultFilter}),
    {
      enabled: !!filter.search || !!defaultFilter.search,
    },
  );

  return {
    data,
    isLoading,
    isError,
    error,
    filter,
    setFilter,
  };
}

const QUERY_POSTAL_CODE = 'postal_codes';

async function getPostalCodes(filter) {
  const {data} = await axios.get(`api/v1/admin/state`, {
    params: {...filter},
  });

  return data;
}

export function usePostalCode() {
  const [filter, setFilter] = useState({
    sortBy: 'desc',
    sortOrder: 'name',
    limit: 10,
    search: '',
    page: 1,
  });
  const fallback = [];
  const {
    data = fallback,
    isLoading,
    isError,
    error,
  } = useQuery(
    [QUERY_POSTAL_CODE, filter],
    async () => getPostalCodes(filter),
    {
      enabled: !!filter.search,
    },
  );

  return {
    data,
    isLoading,
    isError,
    error,
    filter,
    setFilter,
  };
}

async function getUnits(filter) {
  const {data} = await axios.get(`api/v1/admin/unit`, {
    params: {...filter},
  });

  return data;
}

export function useUnit() {
  const [filter, setFilter] = useState({
    // sortBy: 'desc',
    // sortOrder: 'name',
    // limit: 10,
    // search: '',
    // page: 1,
  });
  const fallback = [];
  const {
    data = fallback,
    isLoading,
    isError,
    error,
  } = useQuery([QUERY_POSTAL_CODE, filter], async () => getUnits(filter), {
    enabled: true,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    filter,
    setFilter,
  };
}

async function getMenus(filter) {
  const {data} = await axios.get(`api/v1/admin/menu_permission`, {
    params: {...filter},
  });

  return data;
}

export function useMenu() {
  const [filter, setFilter] = useState({});
  const fallback = [];
  const {
    data = fallback,
    isLoading,
    isError,
    error,
  } = useQuery(['menu', filter], async () => getMenus(filter), {
    enabled: true,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    filter,
    setFilter,
  };
}

async function getVehicleTypes(filter) {
  const {data} = await axios.get(`api/v1/admin/vehicle_type`, {
    params: {...filter},
  });

  return data;
}

export function useVehicleType() {
  const [filter, setFilter] = useState({});
  const fallback = [];
  const {
    data = fallback,
    isLoading,
    isError,
    error,
  } = useQuery(['vehicle_type', filter], async () => getVehicleTypes(filter), {
    enabled: true,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    filter,
    setFilter,
  };
}
