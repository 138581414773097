import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AppDialog from '@crema/core/AppDialog';

import {Fonts} from 'shared/constants/AppEnums';

const AddNewTag = ({
  label,
  dialogChildren,
  onOpenListDialog,
  approveLabel = 'OK',
  onApprove,
}) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const onOpenDialog = () => {
    onOpenListDialog();
    setIsOpenDialog(true);
  };

  const onCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const handleApprove = () => {
    onApprove();
    onCloseDialog();
  };

  return (
    <>
      <AppDialog
        dividers
        maxWidth='md'
        open={isOpenDialog}
        onClose={() => onCloseDialog()}
        title={label}
      >
        {dialogChildren}
        <Grid container justifyContent='flex-end'>
          <Stack direction='row' spacing={4}>
            <Button onClick={onCloseDialog} variant='contained' color='error'>
              Back
            </Button>
            <Button onClick={handleApprove} variant='contained'>
              {approveLabel}
            </Button>
          </Stack>
        </Grid>
      </AppDialog>
      <Box
        component='p'
        sx={{
          m: 2,
          ml: 3,
          color: 'primary.main',
          fontSize: {xs: 16, xl: 18},
          cursor: 'pointer',
          fontWeight: Fonts.BOLD,
        }}
        onClick={() => onOpenDialog()}
      >
        <Typography>Select More...</Typography>
      </Box>
    </>
  );
};

AddNewTag.propTypes = {
  label: PropTypes.string,
  approveLabel: PropTypes.string,
  dialogChildren: PropTypes.element,
  onOpenListDialog: PropTypes.any,
  onApprove: PropTypes.func,
};

export default AddNewTag;
