import React from 'react';

const Main = React.lazy(() => import('./Main'));

export const dashboardPagesConfigs = [
  {
    path: '/dashboard',
    element: <Main />,
  },
];
