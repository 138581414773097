import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import {Stack} from '@mui/material';

export const BasicSelect = ({
  minWidth = 120,
  maxWidth = 360,
  options,
  label,
  required = false,
  error = false,
  errorText,
  disabled,
  value,
  defaultValue = '',
  name,
  placeholder = '',
  onChange = () => {},
  onBlur = () => {},
}) => {
  return (
    <Stack>
      <InputLabel required={required}>{label}</InputLabel>
      <FormControl disabled={disabled} error={error} sx={{minWidth, maxWidth}}>
        <Select
          onChange={(e, reactElement) => {
            onChange(e, reactElement);
          }}
          value={value}
          defaultValue={defaultValue}
          onBlur={onBlur}
          name={name}
          placeholder={placeholder}
          displayEmpty
        >
          <MenuItem disabled value=''>
            <em>{placeholder || 'Select...'}</em>
          </MenuItem>
          {options.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        {error && !!errorText && <FormHelperText>{errorText}</FormHelperText>}
      </FormControl>
    </Stack>
  );
};

BasicSelect.propTypes = {
  minWidth: PropTypes.any,
  maxWidth: PropTypes.any,
  options: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default BasicSelect;
