import {useState} from 'react';
import {useQuery} from 'react-query';
import axios from 'services/axios';

export const QUERY = 'billing-report';

export async function getBillingReports(filter) {
  const {data} = await axios.get(`api/v2/admin/bill`, {
    params: {...filter},
  });

  return data;
}

export function useBillingReport(defaultFilter = {}) {
  const [filter, setFilter] = useState({
    page: 1,
    search: null,
    sortBy: 'desc',
    sortOrder: 'created_at',
    limit: 10,
  });
  const fallback = [];
  const {
    data = fallback,
    isLoading,
    isError,
    error,
  } = useQuery([QUERY, filter, defaultFilter], async () =>
    getBillingReports({...filter, ...defaultFilter}),
  );

  return {
    data,
    isLoading,
    isError,
    error,
    filter,
    setFilter,
  };
}

export const fetchBillingReport = (billingReportId) => {
  return axios
    .get(`api/v2/admin/bill/${billingReportId}`)
    .then((res) => res.data);
};

export function useDetailBillingReport(billingReportId) {
  return useQuery({
    queryKey: billingReportId && [QUERY, billingReportId],
    queryFn: () => fetchBillingReport(billingReportId),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!billingReportId,
  });
}

export const fetchBillCollectible = (bill_ids) => {
  return axios
    .post(`api/v1/admin/bill/collectible`, {bill_ids})
    .then((res) => res.data);
};

export function useCollectibleBillingReport(bill_ids) {
  return useQuery({
    queryKey: [QUERY, 'bill-collectible', bill_ids],
    queryFn: () => fetchBillCollectible(bill_ids),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!bill_ids?.length,
  });
}

export const fetchBillInvoices = (customer_id) => {
  return axios
    .get(`api/v2/admin/bill/invoices`, {params: {customer_id}})
    .then((res) => res.data);
};

export function useInvoicesBillingReport(customer_id) {
  return useQuery({
    queryKey: [QUERY, 'bill-invoices', customer_id],
    queryFn: () => fetchBillInvoices(customer_id),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!customer_id,
  });
}
