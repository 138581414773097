import {useState} from 'react';
import {useQuery} from 'react-query';
import axios from 'services/axios';

export const QUERY = 'notification';

async function getNotifications(filter) {
  const {data} = await axios.get(`api/v1/admin/notification`, {
    params: {...filter},
  });

  return data;
}

export function useNotification() {
  const [filter, setFilter] = useState({
    page: 1,
    search: null,
    sortBy: 'desc',
    sortOrder: 'created_at',
    limit: 20,
  });
  const fallback = [];
  const {
    data = fallback,
    isLoading,
    isError,
    error,
  } = useQuery([QUERY, filter], async () => getNotifications(filter), {});

  return {
    data,
    isLoading,
    isError,
    error,
    filter,
    setFilter,
  };
}

export const fetchNotification = (notificationId) => {
  return axios
    .get(`api/v1/admin/notification/${notificationId}`)
    .then((res) => res.data);
};

export function useDetailNotification(notificationId) {
  return useQuery({
    queryKey: notificationId && [QUERY, notificationId],
    queryFn: () => fetchNotification(notificationId),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!notificationId,
  });
}

export const fetchNotificationUnread = () => {
  return axios
    .get(`api/v1/admin/notification/total-unread`)
    .then((res) => res.data);
};

export function useNotificationUnread() {
  return useQuery({
    queryKey: [QUERY],
    queryFn: () => fetchNotificationUnread(),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
}
