import {useState} from 'react';
import {useQuery} from 'react-query';
import axios from 'services/axios';

export const QUERY = 'shipping';

export async function getShippingManagements(filter) {
  let {route_id} = filter;
  if (typeof filter.route_id === 'object') {
    route_id = route_id.value;
  }

  const {data} = await axios.get(`api/v1/admin/shipping`, {
    params: {...filter, route_id},
  });

  return data;
}

export function useShippingManagement(
  defaultFilter = {},
  enableOnlyWithEtd = false,
  options = {},
) {
  const [filter, setFilter] = useState({
    page: 1,
    search: null,
    limit: 10,
    sortBy: 'desc',
    sortOrder: 'created_at',
  });
  const fallback = [];
  const {
    data = fallback,
    isLoading,
    isError,
    error,
  } = useQuery(
    [QUERY, filter, defaultFilter],
    async () => getShippingManagements({...filter, ...defaultFilter}),
    {
      enabled: !enableOnlyWithEtd || !!defaultFilter.etd,
      ...options,
    },
  );

  return {
    data,
    isLoading,
    isError,
    error,
    filter,
    setFilter,
  };
}

export const fetchShippingManagement = (shippingManagementId) => {
  return axios
    .get(`api/v1/admin/shipping/${shippingManagementId}`)
    .then((res) => res.data);
};

export function useDetailShippingManagement(shippingManagementId) {
  return useQuery({
    queryKey: shippingManagementId && [QUERY, shippingManagementId],
    queryFn: () => fetchShippingManagement(shippingManagementId),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!shippingManagementId,
  });
}

export async function downloadShippingManagement(filter) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`api/v1/admin/shipping/export`, {
        params: {...filter},
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/octet-stream',
        },
      })
      .then((response) => {
        const blob = new Blob(
          [response.data],
          {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
          }, //For excel file
        );

        const aEle = document.createElement('a'); // Create a label
        const href = window.URL.createObjectURL(blob); // Create downloaded link

        aEle.href = href;
        aEle.download = 'Jadwal-kapal.xlsx'; // File name after download
        document.body.appendChild(aEle);
        aEle.click(); // Click to download
        document.body.removeChild(aEle); // Download complete remove element
        window.URL.revokeObjectURL(href); // Release blob object

        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}
