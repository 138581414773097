import React from 'react';
import PropTypes from 'prop-types';
import {
  // TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
  Stack,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import convertToCurrency from 'utils/convertToCurrency';
import convertToPhone from 'utils/convertToPhone';

export const BasicTextField = ({
  label,
  name,
  required = false,
  error = false,
  errorText = '',
  isEmail = false,
  isNumber = false,
  isSecret = false,
  sxLabel = {},
  sxInput = {},
  value = '',
  endAdornment,
  placeholder,
  multiline,
  disabled,
  rows,
  children,
  maskingNumber,
  onChange = () => {},
  onBlur = () => {},
  onFocus = () => {},
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let type = 'text';
  // if (isNumber && !isEmail && !isSecret) type = "number";
  if (!isNumber && isEmail && !isSecret) type = 'email';
  if (!isNumber && !isEmail && isSecret) {
    return (
      <Stack>
        <InputLabel sx={{...sxLabel}}>{label}</InputLabel>
        <FormControl fullWidth>
          <OutlinedInput
            sx={{...sxInput}}
            fullWidth
            value={value}
            required={required}
            // helperText={error ? errorText : null}
            error={error}
            variant='outlined'
            onChange={(event) => onChange(event)}
            onBlur={onBlur}
            disabled={disabled}
            name={name}
            type={showPassword ? 'text' : 'password'}
            placeholder={placeholder}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Typography variant='caption' color='error' sx={{ml: 4}}>
          {error ? errorText : null}
        </Typography>
      </Stack>
    );
  }

  const numberFormater = (number) => {
    switch (maskingNumber) {
      case 'currency':
        return convertToCurrency(number);
      case 'phone':
        return convertToPhone(number);
      default:
        return number;
    }
  };

  return (
    <Stack>
      <InputLabel sx={{...sxLabel}}>{label}</InputLabel>
      <FormControl fullWidth>
        <OutlinedInput
          sx={{...sxInput}}
          fullWidth
          multiline={multiline}
          rows={rows}
          value={isNumber ? numberFormater(value) : value}
          name={name}
          required={required}
          error={error}
          // helperText={error ? errorText : null}
          type={type}
          onChange={(event) => {
            if (isNumber) {
              event.target.value = event.target.value.replace(/[^0-9-]/g, '');
              onChange(event);
            } else {
              onChange(event);
            }
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          placeholder={placeholder}
          startAdornment={
            maskingNumber === 'currency' ? (
              <InputAdornment position='start'>Rp</InputAdornment>
            ) : null
          }
          endAdornment={
            endAdornment ? (
              <InputAdornment position='end'>{endAdornment}</InputAdornment>
            ) : null
          }
        >
          {children}
        </OutlinedInput>
      </FormControl>
      <Typography variant='caption' color='error' sx={{ml: 4}}>
        {error ? errorText : null}
      </Typography>
    </Stack>
  );
};

BasicTextField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  isEmail: PropTypes.bool,
  isNumber: PropTypes.bool,
  isSecret: PropTypes.bool,
  sxLabel: PropTypes.object,
  sxInput: PropTypes.object,
  value: PropTypes.any,
  endAdornment: PropTypes.any,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
  children: PropTypes.element,
  maskingNumber: PropTypes.oneOf(['currency', 'phone']),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

export default BasicTextField;
