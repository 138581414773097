import React from 'react';

const List = React.lazy(() => import('./List'));
const Detail = React.lazy(() => import('./Detail'));
const DetailCredit = React.lazy(() => import('./DetailCredit'));

export const receivablesReportPagesConfigs = [
  {
    path: '/receivables-report',
    element: <List />,
  },
  {
    path: '/receivables-report/:id/detail',
    element: <Detail />,
  },
  {
    path: '/receivables-report/:id/detail-credit',
    element: <DetailCredit />,
  },
];
