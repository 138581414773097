import React from 'react';

const List = React.lazy(() => import('./List'));
const Edit = React.lazy(() => import('./Edit'));

export const portPricePagesConfigs = [
  {
    path: '/port-price',
    element: <List />,
  },
  {
    path: '/port-price/:id/edit',
    element: <Edit />,
  },
];
