export default function convertToCurrency(angka) {
  if (angka === '-') {
    return angka;
  }

  if (angka) {
    // Menghapus semua karakter non-digit kecuali tanda minus
    let sanitizedInput = String(angka).replace(/[^0-9.-]/g, '');

    // Mengonversi input yang telah dibersihkan menjadi angka
    let amount = parseFloat(sanitizedInput);
    if (isNaN(amount)) {
      return '';
    }

    // Memformat angka menjadi format Rupiah tanpa simbol "Rp"
    let formattedAmount = amount.toLocaleString('id-ID');

    if (amount < 0) {
      formattedAmount = '-' + formattedAmount.replace('-', '');
    }

    return formattedAmount;
  } else {
    return angka;
  }
}
