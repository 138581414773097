import React from 'react';

const List = React.lazy(() => import('./List'));
const Create = React.lazy(() => import('./Create'));
const Edit = React.lazy(() => import('./Edit'));
const Detail = React.lazy(() => import('./Detail'));

export const portManagementPagesConfigs = [
  {
    path: '/port',
    element: <List />,
  },
  {
    path: '/port/create',
    element: <Create />,
  },
  {
    path: '/port/:id',
    element: <Detail />,
  },
  {
    path: '/port/:id/edit',
    element: <Edit />,
  },
];
