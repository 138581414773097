import React from 'react';

const Main = React.lazy(() => import('./Main'));

export const lclPagesConfigs = [
  {
    path: '/lcl-price',
    element: <Main />,
  },
];
