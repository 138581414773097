import React from 'react';
import {Box, Typography} from '@mui/material';
import IntlMessages from '@crema/utility/IntlMessages';
import {Fonts} from 'shared/constants/AppEnums';
import ChangePasswordForm from './ChangePasswordForm';
import {Formik} from 'formik';
import * as yup from 'yup';
import {useChangePassword} from 'hooks/Auth';
import {useDispatch} from 'react-redux';
import {showMessage, fetchError} from 'redux/actions/Common';

const validationSchema = yup.object({
  old_password: yup
    .string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  // .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  new_password: yup
    .string()
    .required('New password required.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  // .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('new_password'), null], 'Passwords must match'),
});

const ChangePassword = () => {
  const {mutate} = useChangePassword();
  const dispatch = useDispatch();
  const formikRef = React.useRef();

  const handleSubmit = (formValue) => {
    mutate(formValue, {
      onSuccess: () => {
        dispatch(showMessage('Change Password Successfully'));
        formikRef.current.resetForm();
      },
      onError: (res) => {
        console.log(res);
        dispatch(
          fetchError(res?.response?.data?.errorMessage || 'Something Error'),
        );
      },
    });
  };
  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: 550,
      }}
    >
      <Typography
        component='h3'
        sx={{
          fontSize: 16,
          fontWeight: Fonts.BOLD,
          mb: {xs: 3, lg: 5},
        }}
      >
        <IntlMessages id='common.changePassword' />
      </Typography>
      <Formik
        validateOnChange={false}
        validateOnBlur={true}
        innerRef={formikRef}
        initialValues={{
          old_password: '',
          new_password: '',
          password_confirmation: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(data, {setSubmitting}) => {
          setSubmitting(true);
          setSubmitting(false);
        }}
      >
        {({values}) => (
          <ChangePasswordForm handleSubmit={handleSubmit} values={values} />
        )}
      </Formik>
    </Box>
  );
};

export default ChangePassword;
