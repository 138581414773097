import React from 'react';

const List = React.lazy(() => import('./List'));
const Detail = React.lazy(() => import('./Detail'));

export const ticketManagementPagesConfigs = [
  {
    path: '/ticket',
    element: <List />,
  },
  {
    path: '/ticket/:id/detail',
    element: <Detail />,
  },
];
