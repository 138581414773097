import React from 'react';

const List = React.lazy(() => import('./List'));
const Edit = React.lazy(() => import('./Edit'));
const Create = React.lazy(() => import('./Create'));

export const ttbPagesConfigs = [
  {
    path: '/ttb',
    element: <List />,
  },
  {
    path: '/ttb/create',
    element: <Create />,
  },
  {
    path: '/ttb/:id/edit',
    element: <Edit />,
  },
];
