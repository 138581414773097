import React from 'react';

const List = React.lazy(() => import('./List'));

export const otherPricePagesConfigs = [
  {
    path: '/other-price',
    element: <List />,
  },
];
