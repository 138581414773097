import React from 'react';

const List = React.lazy(() => import('./List'));
const Create = React.lazy(() => import('./Create'));
const Edit = React.lazy(() => import('./Edit'));
const GenerateDoc = React.lazy(() => import('./GenerateDoc'));
const Dropoff = React.lazy(() => import('./Dropoff'));
const ContainerBelumDoor = React.lazy(() => import('./containerBelumDoor'));
const ListDestination = React.lazy(() => import('./ListDestination'));

export const shipDepartPagesConfigs = [
  {
    path: '/ship-departure',
    element: <List />,
  },
  {
    path: '/ship-departure/create',
    element: <Create />,
  },
  {
    path: '/ship-departure/:id/edit',
    element: <Edit />,
  },
  {
    path: '/ship-departure/:id/generate-doc',
    element: <GenerateDoc />,
  },
  {
    path: '/ship-departure/:id/dropoff',
    element: <Dropoff />,
  },
  {
    path: '/container-belum-door/:id/belum-door',
    element: <ContainerBelumDoor />,
  },
  {
    path: '/container-belum-door',
    element: <ListDestination />,
  },
];
