import React from 'react';

const Main = React.lazy(() => import('./Main'));
const Create = React.lazy(() => import('./Create'));
const Edit = React.lazy(() => import('./Edit'));

export const vendorTruckingManagementPagesConfigs = [
  {
    path: '/vendor-trucking-management',
    element: <Main />,
  },
  {
    path: '/vendor-trucking-management/create',
    element: <Create />,
  },
  {
    path: '/vendor-trucking-management/:id/edit',
    element: <Edit />,
  },
];
