/* eslint-disable no-unused-vars */
import React from 'react';
import BasicDialog from './BasicDialog';
import {
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';
import convertToCurrency from 'utils/convertToCurrency';
import {BasicSelect, BasicTextField} from 'components/Form';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  useSplitInvoiceBillingReport,
  useCollectibleBillingReport,
  fetchBillInvoices,
} from 'hooks/BillingReport';
import {showMessage, fetchError} from 'redux/actions/Common';
import {useDispatch} from 'react-redux';

const TableCellHead = ({children, minWidth}) => (
  <TableCell
    sx={{
      textTransform: 'uppercase',
      color: '#2155CD',
      fontWeight: 600,
      minWidth: minWidth,
    }}
  >
    {children}
  </TableCell>
);

TableCellHead.propTypes = {
  children: PropTypes.node,
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

// const expectedPayload = {
//   bill_details: [
//     {
//       id: '',
//       first_billed_amount: '',
//       first_billed_to: '',
//       first_notes: '',
//       second_billed_amount: '',
//       second_billed_to: '',
//       second_notes: '',
//     },
//   ]
// };

export const DialogSplitInvoice = ({isOpen, onClose, detail, invoiceNo}) => {
  const dispatch = useDispatch();
  const [payload, setPayload] = React.useState([]);
  // const [invoiceId, setInvoiceId] = React.useState('');
  // const [invoiceCustomerId, setInvoiceCustomerId] = React.useState(null);

  const {mutate: mutateSplitInvoice, isLoading: isLoadingSplitInvoice} =
    useSplitInvoiceBillingReport(detail?.data?.id);
  const handleSplitInvoice = () => {
    mutateSplitInvoice(
      {
        bill_details: payload.map((item) => ({
          ...item,
          invoice_id: item.invoice_id || null,
          price: undefined,
        })),
      },
      {
        onSuccess: () => {
          dispatch(showMessage('Split Invoice Berhasil'));
          setPayload([]);
          onClose();
        },
        onError: (res) => {
          console.log(res);
          dispatch(
            fetchError(res?.response?.data?.message || 'Something Error'),
          );
        },
      },
    );
  };

  React.useEffect(() => {
    const detailPayload = [];
    detail?.data?.bill_details?.forEach((bill) => {
      bill?.items?.forEach((container) => {
        detailPayload.push({
          bill_id: bill.id,
          id: container.id,
          first_billed_amount: '',
          first_billed_to: detail?.data?.billed_to_customer_id,
          first_notes: '',
          second_billed_amount: '',
          second_billed_to: '',
          second_notes: '',
          invoice_id: null,
          price: container.price,
        });
      });
    });
    setPayload([...detailPayload]);
  }, [detail]);

  const setBillDetails = React.useCallback(
    (id, name, value) => {
      setPayload((prev) =>
        prev.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              [name]: value,
            };
          }

          return item;
        }),
      );
    },
    [payload],
  );

  const setCustomerBilledTo = React.useCallback(
    (id, name, value) => {
      setPayload((prev) =>
        prev.map((item) => {
          if (item.bill_id === id) {
            return {
              ...item,
              [name]: value,
            };
          }

          return item;
        }),
      );
    },
    [payload],
  );

  const getValue = (id, name) => {
    const find = payload.find((item) => item.id === id);

    return find?.[name];
  };

  const getValueBilledTo = (id, name) => {
    const find = payload.find((item) => item.bill_id === id);

    return find?.[name];
  };

  const {data: collectibleData} = useCollectibleBillingReport([
    detail?.data?.id,
  ]);
  const [collectibleOptions, setCollectibleOptions] = React.useState([]);
  React.useEffect(() => {
    if (collectibleData?.status) {
      setCollectibleOptions(
        collectibleData?.data?.map((item) => ({
          label: item.name,
          value: item.id,
          detail: item,
        })),
      );
    }
  }, [collectibleData]);

  // const {data: invoicesData} = useInvoicesBillingReport(invoiceCustomerId);
  // const [invoicesOptions, setInvoicesOptions] = React.useState([
  //   {label: 'New Invoices', value: ''},
  // ]);
  // React.useEffect(() => {
  //   if (invoicesData?.status) {
  //     setInvoicesOptions([
  //       {label: 'New Invoices', value: ''},
  //       ...(invoicesData?.data?.map((item) => ({
  //         label: item.name,
  //         value: item.id,
  //       })) || []),
  //     ]);
  //   }
  // }, [invoicesData]);

  // React.useEffect(() => {
  //   const secondBilledTo = payload[0]?.second_billed_to;
  //   if (payload.every((item) => item.second_billed_to === secondBilledTo)) {
  //     setInvoiceCustomerId(secondBilledTo);
  //   }
  // }, [payload]);

  const [errors, setErrors] = React.useState([]);
  const inputError = React.useCallback(
    (value, id, name) => {
      const findContainer = payload.find((item) => item.id === id);
      const findError = errors.find((item) => item.id === id);

      if (findContainer?.price < value) {
        if (!findError) {
          setErrors((prev) => [
            ...prev,
            {
              id,
              name,
              text: `Maksimal ${convertToCurrency(findContainer.price)}`,
            },
          ]);
        }
      } else {
        if (findError) {
          setErrors((prev) => {
            return prev.filter((item) => item.id !== id);
          });
        }
      }
    },
    [errors, payload],
  );

  const [invoiceOptions, setInvoiceOptions] = React.useState([]);
  const setInvoiceByCustomer = React.useCallback(async (id, customerId) => {
    const {data} = await fetchBillInvoices(customerId);
    if (data?.length) {
      const options = [
        {label: 'New Invoices', value: ''},
        ...(data?.map((item) => ({
          label: item.name,
          value: item.id,
        })) || []),
      ];
      setInvoiceOptions((prev) => {
        return prev
          .filter((item) => item.id !== id)
          .push({
            id,
            options,
          });
      });
    }
  }, []);

  return (
    <BasicDialog
      isOpenDialog={isOpen}
      onClose={onClose}
      maxWidth='xl'
      title='Split Invoice'
    >
      <Grid container spacing={6}>
        <Grid item sm={12}>
          <TableContainer component={Paper} sx={{maxWidth: '100%'}}>
            <Table>
              <TableHead sx={{background: '#F3FDFF'}}>
                <TableRow>
                  <TableCellHead minWidth={200}>Keterangan</TableCellHead>
                  <TableCellHead minWidth={100}>Value</TableCellHead>
                  <TableCellHead>Tarif</TableCellHead>
                  <TableCellHead>Jumlah Tertagih</TableCellHead>
                  <TableCellHead>Ditagih Ke</TableCellHead>
                  <TableCellHead>Notes</TableCellHead>
                  <TableCellHead>Jumlah Tertagih</TableCellHead>
                  <TableCellHead>Ditagih Ke</TableCellHead>
                  <TableCellHead>Notes</TableCellHead>
                  <TableCellHead>Invoice</TableCellHead>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component='th' scope='row' colSpan={9}>
                    Order {invoiceNo}
                  </TableCell>
                </TableRow>
                {detail?.data?.bill_details?.map((item) => (
                  <>
                    <TableRow sx={{background: '#F3FDFF'}}>
                      <TableCell component='th' scope='row'>
                        Pengiriman - {item.name}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {item.items?.length} Cont
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {convertToCurrency(item.price)}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {convertToCurrency(item.total)}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {detail?.data && !!collectibleOptions?.length && (
                          <BasicSelect
                            placeholder='Pilih Customer'
                            options={collectibleOptions}
                            value={getValueBilledTo(item.id, 'first_billed_to')}
                            onChange={(e) =>
                              setCustomerBilledTo(
                                item.id,
                                'first_billed_to',
                                e.target.value,
                              )
                            }
                          />
                        )}
                      </TableCell>
                      <TableCell component='th' scope='row'></TableCell>
                      <TableCell component='th' scope='row'></TableCell>
                      <TableCell component='th' scope='row'>
                        <BasicSelect
                          placeholder='Pilih Customer'
                          options={collectibleOptions}
                          value={getValueBilledTo(item.id, 'second_billed_to')}
                          onChange={(e) => {
                            setCustomerBilledTo(
                              item.id,
                              'second_billed_to',
                              e.target.value,
                            );
                            setInvoiceByCustomer(item.id, e.target.value);
                          }}
                        />
                      </TableCell>
                      <TableCell component='th' scope='row'></TableCell>
                      <TableCell component='th' scope='row'></TableCell>
                    </TableRow>
                    {item.items?.map((container) => (
                      <TableRow key={container.id}>
                        <TableCell component='th' scope='row'>
                          {container.no_container} - {container.no_seal}
                        </TableCell>
                        <TableCell component='th' scope='row'></TableCell>
                        <TableCell component='th' scope='row'></TableCell>
                        <TableCell component='th' scope='row'>
                          <BasicTextField
                            isNumber
                            maskingNumber='currency'
                            value={getValue(
                              container.id,
                              'first_billed_amount',
                            )}
                            onChange={(e) => {
                              setBillDetails(
                                container.id,
                                'first_billed_amount',
                                +e.target.value,
                              );
                              setBillDetails(
                                container.id,
                                'second_billed_amount',
                                container.price - +e.target.value,
                              );
                              inputError(
                                +e.target.value,
                                container.id,
                                'first_billed_amount',
                              );
                            }}
                            error={
                              !!errors?.find(
                                (item) =>
                                  item.id === container.id &&
                                  item.name === 'first_billed_amount',
                              )
                            }
                            errorText={
                              errors?.find(
                                (item) =>
                                  item.id === container.id &&
                                  item.name === 'first_billed_amount',
                              )?.text
                            }
                          />
                        </TableCell>
                        <TableCell component='th' scope='row'></TableCell>
                        <TableCell component='th' scope='row'>
                          <BasicTextField
                            value={getValue(container.id, 'first_notes')}
                            onChange={(e) =>
                              setBillDetails(
                                container.id,
                                'first_notes',
                                e.target.value,
                              )
                            }
                          />
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <BasicTextField
                            isNumber
                            maskingNumber='currency'
                            value={getValue(
                              container.id,
                              'second_billed_amount',
                            )}
                            onChange={(e) => {
                              setBillDetails(
                                container.id,
                                'second_billed_amount',
                                +e.target.value,
                              );
                              setBillDetails(
                                container.id,
                                'first_billed_amount',
                                container.price - +e.target.value,
                              );
                              inputError(
                                +e.target.value,
                                container.id,
                                'second_billed_amount',
                              );
                            }}
                            error={
                              !!errors?.find(
                                (item) =>
                                  item.id === container.id &&
                                  item.name === 'second_billed_amount',
                              )
                            }
                            errorText={
                              errors?.find(
                                (item) =>
                                  item.id === container.id &&
                                  item.name === 'second_billed_amount',
                              )?.text
                            }
                          />
                        </TableCell>
                        <TableCell component='th' scope='row'></TableCell>
                        <TableCell component='th' scope='row'>
                          <BasicTextField
                            value={getValue(container.id, 'second_notes')}
                            onChange={(e) =>
                              setBillDetails(
                                container.id,
                                'second_notes',
                                e.target.value,
                              )
                            }
                          />
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <BasicSelect
                            options={
                              invoiceOptions?.find(
                                (invoice) => invoice.id === item.id,
                              ) || []
                            }
                            value={getValue(container.id, 'invoice_id')}
                            onChange={(e) =>
                              setBillDetails(
                                container.id,
                                'invoice_id',
                                e.target.value,
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {/* <Grid item sm={12}>
          <BasicSelect
            label='Pilihan Split'
            options={invoicesOptions}
            onChange={(e) => setInvoiceId(e.target.value)}
            value={invoiceId}
          />
        </Grid> */}
        <Grid item sm={12}>
          <Stack direction='row' justifyContent='flex-end' spacing={4}>
            <Button variant='contained' color='error'>
              Cancel
            </Button>
            <LoadingButton
              color='primary'
              variant='contained'
              loading={isLoadingSplitInvoice}
              type='button'
              disabled={!!errors?.length}
              onClick={handleSplitInvoice}
            >
              Submit
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </BasicDialog>
  );
};

DialogSplitInvoice.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  detail: PropTypes.object,
  invoiceNo: PropTypes.string,
};

export default DialogSplitInvoice;
