import {SET_FILTER_ROLE, RESET_FILTER_ROLE} from 'shared/constants/ActionTypes';

const INIT_STATE = {
  page: 1,
  search: null,
  limit: 10,
  sortBy: 'desc',
  sortOrder: 'created_at',
};

const filterRoleReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_FILTER_ROLE: {
      return {...state, ...action.payload};
    }
    case RESET_FILTER_ROLE: {
      return {
        page: 1,
        search: null,
        limit: 10,
        sortBy: 'desc',
        sortOrder: 'created_at',
      };
    }
    default:
      return state;
  }
};
export default filterRoleReducer;
