import React from 'react';

const List = React.lazy(() => import('./List'));
const Create = React.lazy(() => import('./Create'));
const Edit = React.lazy(() => import('./Edit'));
const Detail = React.lazy(() => import('./Detail'));

export const claimManagementPagesConfigs = [
  {
    path: '/claim-management',
    element: <List />,
  },
  {
    path: '/claim-management/create',
    element: <Create />,
  },
  {
    path: '/claim-management/:id/edit',
    element: <Edit />,
  },
  {
    path: '/claim-management/:id/detail',
    element: <Detail />,
  },
];
