import axios from 'services/axios';
import {useMutation, useQueryClient} from 'react-query';

export function useUploadFile(query) {
  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      axios.post(`api/v1/admin/upload`, values).then((res) => res.data),
    {
      onSuccess: () => {
        if (query) {
          queryClient.invalidateQueries(query);
          queryClient.removeQueries(query);
        }
      },
    },
  );
}
