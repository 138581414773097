import React, {useEffect, useState} from 'react';
import {IconButton, Tooltip} from '@mui/material';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppNotificationContent from './AppNotificationContent';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {alpha} from '@mui/material/styles';
import {
  useNotification,
  useNotificationUnread,
  QUERY,
} from 'hooks/Notification';
import {useQueryClient} from 'react-query';
import {red} from '@mui/material/colors';
import Pusher from 'pusher-js';
import CONFIG from 'config/env';

const PUSHER_KEY = CONFIG.endpoint.PUSHER_KEY;
const PUSHER_CLUSTER = CONFIG.endpoint.PUSHER_CLUSTER;

const AppNotifications = ({
  drawerPosition,
  tooltipPosition,
  isMenu,
  sxNotificationContentStyle,
}) => {
  const queryClient = useQueryClient();
  useEffect(() => {
    const pusher = new Pusher(`${PUSHER_KEY}`, {
      cluster: PUSHER_CLUSTER,
    });
    const channel = pusher.subscribe(`admin`);
    channel.bind('notification', function () {
      queryClient.invalidateQueries(QUERY);
      queryClient.removeQueries(QUERY);
    });

    return pusher.unsubscribe('admin');
  }, []);

  const [showNotification, setShowNotification] = useState(false);
  const {data, filter, setFilter} = useNotification();
  const [notificationData, setNotificationData] = useState([]);
  useEffect(() => {
    if (data?.status) {
      const newData =
        data?.data?.rows?.map((item) => ({
          id: item.id,
          image: item.subject,
          name: item.subject,
          message: item.body,
          data: item.data,
          readAt: item.read_at,
          createdAt: item.created_at,
        })) || [];

      if (data?.data?.current_page < filter.page) {
        setNotificationData((prev) => [...prev, ...newData]);
      } else {
        setNotificationData(() => [...newData]);
      }
    }
  }, [data]);

  const handleLoadMore = () => {
    setFilter({...filter, page: filter.page + 1});
  };

  const {data: dataUnread} = useNotificationUnread();
  const unreadCount = dataUnread?.data || 0;

  return (
    <>
      {isMenu ? (
        <Box component='span' onClick={() => setShowNotification(true)}>
          Message
        </Box>
      ) : (
        <Tooltip title='Notification' placement={tooltipPosition}>
          <IconButton
            className='icon-btn'
            sx={{
              borderRadius: '50%',
              width: 40,
              height: 40,
              color: (theme) => theme.palette.text.secondary,
              backgroundColor: (theme) => theme.palette.background.default,
              border: 1,
              borderColor: 'transparent',
              '&:hover, &:focus': {
                color: (theme) => theme.palette.text.primary,
                backgroundColor: (theme) =>
                  alpha(theme.palette.background.default, 0.9),
                borderColor: (theme) =>
                  alpha(theme.palette.text.secondary, 0.25),
              },
            }}
            onClick={() => setShowNotification(true)}
            size='large'
          >
            <NotificationsNoneIcon />
            {unreadCount > 0 && (
              <Box
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  zIndex: 1,
                  width: 22,
                  height: 22,
                  borderRadius: '50%',
                  border: `2px solid white`,
                  backgroundColor: red[600],
                }}
              >
                <p
                  style={{
                    color: 'white',
                    fontSize: 10,
                    marginTop: 4,
                  }}
                >
                  {unreadCount > 99 ? '99+' : unreadCount}
                </p>
              </Box>
            )}
          </IconButton>
        </Tooltip>
      )}

      <Drawer
        anchor={drawerPosition}
        open={showNotification}
        onClose={() => setShowNotification(false)}
      >
        <AppNotificationContent
          sxStyle={sxNotificationContentStyle}
          onLoadMore={handleLoadMore}
          canLoadMore={data?.data?.total_page > filter.page}
          notification={notificationData}
          onClose={() => setShowNotification(false)}
        />
      </Drawer>
    </>
  );
};

export default AppNotifications;

AppNotifications.defaultProps = {
  drawerPosition: 'right',
  tooltipPosition: 'bottom',
};

AppNotifications.propTypes = {
  drawerPosition: PropTypes.string,
  tooltipPosition: PropTypes.string,
  isMenu: PropTypes.bool,
  sxNotificationContentStyle: PropTypes.object,
};
