import axios from 'services/axios';
import {useMutation, useQueryClient} from 'react-query';
import {QUERY} from './useQuery';

export function useAddShippingManagement() {
  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      axios.post('api/v1/admin/shipping', values).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}
export function useEditShippingManagement() {
  const queryClient = useQueryClient();

  return useMutation(
    (formData) => {
      const {id} = formData;
      delete formData.id;
      return axios
        .put(`api/v1/admin/shipping/${id}`, formData)
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useDeleteShippingManagement() {
  const queryClient = useQueryClient();

  return useMutation(
    (id) => axios.delete(`api/v1/admin/shipping/${id}`).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}
