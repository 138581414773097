/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import {momentLocalizer, Views, Navigate, Calendar} from 'react-big-calendar';
import moment from 'moment';
// import {StyledCalendar} from './calendar.style';
import {
  Typography,
  Box,
  Stack,
  IconButton,
  Button,
  Divider,
  Tooltip,
} from '@mui/material';
import {MdNavigateNext, MdNavigateBefore} from 'react-icons/md';
import {BasicDialog} from 'components/Dialog';
import {useShippingManagement} from 'hooks/ShippingManagement';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar.css';
import BasicTextField from '../BasicTextField';
import {MdCalendarToday} from 'react-icons/md';
import PropTypes from 'prop-types';

const ColoredDateCellWrapper = ({children}) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  });
const localizer = momentLocalizer(moment);

const MyToolbar = (props) => {
  const {label, onNavigate} = props;

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      sx={{mb: 4}}
    >
      <Typography fontSize={18} fontWeight={500}>
        {label}
      </Typography>
      <Stack direction='row' spacing={2}>
        <IconButton onClick={() => onNavigate(Navigate.PREVIOUS)}>
          <MdNavigateBefore />
        </IconButton>
        <Button
          color='primary'
          size='small'
          onClick={() => onNavigate(Navigate.TODAY)}
        >
          Today
        </Button>
        <IconButton onClick={() => onNavigate(Navigate.NEXT)}>
          <MdNavigateNext />
        </IconButton>
      </Stack>
    </Stack>
  );
};

const MyAgendaEvent = (props) => {
  const {children, event} = props;
  const [isOpenDetail, setIsOpenDetail] = React.useState(false);
  // console.log('props :>> ', props);

  return (
    <>
      {/* <BasicDialog
        title='Event Detail'
        isOpenDialog={isOpenDetail}
        onClose={() => setIsOpenDetail(false)}
        maxWidth='xs'
      >
        <Typography fontSize={16} fontWeight={500}>
          {event.title}
        </Typography>
        <Typography fontSize={10} color='grey'>
          {moment(event.start).format('DD MMM yyy')} -{' '}
          {moment(event.end).format('DD MMM yyy')}
        </Typography>
      </BasicDialog> */}
      <Box sx={{cursor: 'pointer', zIndex: -10}}>
        <Tooltip title={event.title} placement='top'>
          {children}
        </Tooltip>
      </Box>
    </>
  );
};

// const dateCellWrapper = (props) => {
//   return (
//     <Box
//       sx={{
//         borderRight: '0.1px solid #808080',
//         margin: 0,
//         width: '100%',
//       }}
//       onClick={() => console.log('props :>> ', props)}
//     >
//       {props.children}
//     </Box>
//   );
// };

export const ShipScheduleDetailInput = ({inputProps, etd}) => {
  const {data} = useShippingManagement({etd, limit: 99});
  const [events, setEvents] = React.useState([]);
  const [isOpenShowMore, setIsOpenShowMore] = React.useState(false);
  const [eventsShowMore, setEventsShowMore] = React.useState([]);
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState();

  React.useEffect(() => {
    if (data?.status) {
      setEvents(() =>
        data?.data?.rows?.map((item) => ({
          title: `${item.ship?.name} - ${item.cruiser?.name}`,
          allDay: true,
          start: item.etd,
          end: item.eta,
        })),
      );
    }
  }, [data]);

  return (
    <>
      <BasicDialog
        title='Event Detail'
        isOpenDialog={isOpenShowMore}
        onClose={() => setIsOpenShowMore(false)}
        maxWidth='xs'
      >
        <Box>
          {eventsShowMore.map((item, index) => (
            <Box key={index}>
              <Typography fontSize={16} fontWeight={500}>
                {item.title}
              </Typography>
              <Typography fontSize={10} color='grey'>
                {moment(item.start).format('DD MMM yyy')} -{' '}
                {moment(item.end).format('DD MMM yyy')}
              </Typography>
              <Divider sx={{mt: 2, mb: 4}} />
            </Box>
          ))}
        </Box>
      </BasicDialog>
      <BasicDialog
        isOpenDialog={showCalendar}
        title='Pilih jadwal kapal'
        onClose={() => setShowCalendar(false)}
      >
        <Box sx={{backgroundColor: 'white', p: 1, borderRadius: 4}}>
          <Calendar
            events={events}
            views={[Views.MONTH]}
            step={60}
            showMultiDayTimes
            // defaultDate={inputProps.value}
            components={{
              timeSlotWrapper: ColoredDateCellWrapper,
              toolbar: MyToolbar,
              eventWrapper: MyAgendaEvent,
            }}
            onSelectSlot={(slot) => {
              console.log('slot :>> ', slot);
            }}
            selectable={'ignoreEvents'}
            localizer={localizer}
            onSelectEvent={(event, e) => console.log('e :>> ', e, event)}
            // onShowMore={(events, date) => {
            //   setEventsShowMore(events);
            //   setIsOpenShowMore(true);
            // }}
          />
        </Box>
      </BasicDialog>
      <Box onClick={() => setShowCalendar(true)}>
        <BasicTextField
          {...inputProps}
          disabled={!etd}
          endAdornment={
            <MdCalendarToday
              style={{cursor: 'pointer'}}
              onClick={() => {
                if (etd) {
                  setShowCalendar(true);
                }
              }}
            />
          }
        />
      </Box>
    </>
  );
};

ShipScheduleDetailInput.propTypes = {
  inputProps: PropTypes.any,
  date: PropTypes.any,
};

export default ShipScheduleDetailInput;
