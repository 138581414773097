import React from 'react';

const Main = React.lazy(() => import('./Main'));
const Create = React.lazy(() => import('./Create'));
const Detail = React.lazy(() => import('./Detail'));
const Edit = React.lazy(() => import('./Edit'));
const ReOrder = React.lazy(() => import('./ReOrder'));
const InputTtb = React.lazy(() => import('./InputTtb'));
const InputBpb = React.lazy(() => import('./InputBpb'));

export const orderManagementPagesConfigs = [
  {
    path: '/order-management',
    element: <Main />,
  },
  {
    path: '/order-management/create',
    element: <Create />,
  },
  {
    path: '/order-management/:id/detail',
    element: <Detail />,
  },
  {
    path: '/order-management/:id/edit',
    element: <Edit />,
  },
  {
    path: '/order-management/:id/re-order',
    element: <ReOrder />,
  },
  {
    path: '/order-management/:id/input-ttb',
    element: <InputTtb />,
  },
  {
    path: '/order-management/:id/input-bpb',
    element: <InputBpb />,
  },
];
