//API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';
export const IS_CALENDAR = 'is_calendar';
export const SHOW_CALENDAR = 'show_calendar';
export const HIDE_CALENDAR = 'hide_calendar';

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';
export const SET_INITIAL_PATH = 'set_initial_path';

//FILTER ORDER
export const SET_FILTER_ORDER = 'set_filter_order';
export const RESET_FILTER_ORDER = 'reset_filter_order';

//FILTER ROLE
export const SET_FILTER_ROLE = 'set_filter_role';
export const RESET_FILTER_ROLE = 'reset_filter_role';

//FILTER ADMIN
export const SET_FILTER_ADMIN = 'set_filter_admin';
export const RESET_FILTER_ADMIN = 'reset_filter_admin';

//FILTER CUSTOMER
export const SET_FILTER_CUSTOMER = 'set_filter_customer';
export const RESET_FILTER_CUSTOMER = 'reset_filter_customer';

//FILTER ROUTE
export const SET_FILTER_ROUTE = 'set_filter_route';
export const RESET_FILTER_ROUTE = 'reset_filter_route';

//FILTER HPP_TERM
export const SET_FILTER_HPP_TERM = 'set_filter_hpp_term';
export const RESET_FILTER_HPP_TERM = 'reset_filter_hpp_term';

//FILTER CITY
export const SET_FILTER_CITY = 'set_filter_city';
export const RESET_FILTER_CITY = 'reset_filter_city';

//FILTER DISTRICT
export const SET_FILTER_DISTRICT = 'set_filter_district';
export const RESET_FILTER_DISTRICT = 'reset_filter_district';

//FILTER VENDOR_TRUCKING
export const SET_FILTER_VENDOR_TRUCKING = 'set_filter_vendor_trucking';
export const RESET_FILTER_VENDOR_TRUCKING = 'reset_filter_vendor_trucking';

//FILTER COMMODITY
export const SET_FILTER_COMMODITY = 'set_filter_commodity';
export const RESET_FILTER_COMMODITY = 'reset_filter_commodity';

//FILTER VENDOR_SHIP
export const SET_FILTER_VENDOR_SHIP = 'set_filter_vendor_ship';
export const RESET_FILTER_VENDOR_SHIP = 'reset_filter_vendor_ship';

//FILTER HPP_FCL
export const SET_FILTER_HPP_FCL = 'set_filter_hpp_fcl';
export const RESET_FILTER_HPP_FCL = 'reset_filter_hpp_fcl';

//FILTER SHIPPING_PRICE
export const SET_FILTER_SHIPPING_PRICE = 'set_filter_shipping_price';
export const RESET_FILTER_SHIPPING_PRICE = 'reset_filter_shipping_price';

//FILTER PORT_PRICE
export const SET_FILTER_PORT_PRICE = 'set_filter_port_price';
export const RESET_FILTER_PORT_PRICE = 'reset_filter_port_price';

//FILTER OTHER_PRICE
export const SET_FILTER_OTHER_PRICE = 'set_filter_other_price';
export const RESET_FILTER_OTHER_PRICE = 'reset_filter_other_price';

//FILTER SHIP_DEPARTURE
export const SET_FILTER_SHIP_DEPARTURE = 'set_filter_ship_departure';
export const RESET_FILTER_SHIP_DEPARTURE = 'reset_filter_ship_departure';

//FILTER SHIPPING
export const SET_FILTER_SHIPPING = 'set_filter_shipping';
export const RESET_FILTER_SHIPPING = 'reset_filter_shipping';

//FILTER TTB
export const SET_FILTER_TTB = 'set_filter_ttb';
export const RESET_FILTER_TTB = 'reset_filter_ttb';

//FILTER CONTAINER_BELUM_DOOR
export const SET_FILTER_CONTAINER_BELUM_DOOR =
  'set_filter_container_belum_door';
export const RESET_FILTER_CONTAINER_BELUM_DOOR =
  'reset_filter_container_belum_door';

//FILTER RECEIVABLE_REPORT
export const SET_FILTER_RECEIVABLE_REPORT = 'set_filter_receivable_report';
export const RESET_FILTER_RECEIVABLE_REPORT = 'reset_filter_receivable_report';

//FILTER RECEIVABLE_REPORT_DETAIL
export const SET_FILTER_RECEIVABLE_REPORT_DETAIL =
  'set_filter_receivable_report_detail';
export const RESET_FILTER_RECEIVABLE_REPORT_DETAIL =
  'reset_filter_receivable_report_detail';

//FILTER BILLING_REPORT
export const SET_FILTER_BILLING_REPORT = 'set_filter_billing_report';
export const RESET_FILTER_BILLING_REPORT = 'reset_filter_billing_report';

//FILTER CLAIM
export const SET_FILTER_CLAIM = 'set_filter_claim';
export const RESET_FILTER_CLAIM = 'reset_filter_claim';

//FILTER TICKET
export const SET_FILTER_TICKET = 'set_filter_ticket';
export const RESET_FILTER_TICKET = 'reset_filter_ticket';

//FILTER LCL
export const SET_FILTER_LCL = 'set_filter_lcl';
export const RESET_FILTER_LCL = 'reset_filter_lcl';

//FILTER LCL
export const SET_FILTER_PORT = 'set_filter_port';
export const RESET_FILTER_PORT = 'reset_filter_port';
