import React from 'react';
const List = React.lazy(() => import('./List'));
const Detail = React.lazy(() => import('./Detail'));
const Create = React.lazy(() => import('./Create'));
const Edit = React.lazy(() => import('./Edit'));

export const customerManagementPagesConfigs = [
  {
    path: '/customer-management',
    element: <List />,
  },
  {
    path: '/customer-management/create',
    element: <Create />,
  },
  {
    path: '/customer-management/:id',
    element: <Detail />,
  },
  {
    path: '/customer-management/:id/edit',
    element: <Edit />,
  },
];
