import React from 'react';

const List = React.lazy(() => import('./List'));

export const billingReportPagesConfigs = [
  {
    path: '/billing-report',
    element: <List />,
  },
];
