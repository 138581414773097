import axios from 'services/axios';
import {useMutation, useQueryClient} from 'react-query';
import {QUERY} from './useQuery';

export function useLogin() {
  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      axios.post('api/v1/admin/auth/login', values).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useForgotPassword() {
  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      axios
        .post('api/v1/admin/auth/forgot_password', values)
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useResetPassword() {
  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      axios
        .put('api/v1/admin/auth/reset_password', {data: values})
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useChangePassword() {
  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      axios
        .put('api/v1/admin/auth/change_password', values)
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useChangeProfile() {
  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      axios.put('api/v1/admin/auth/profile', values).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}
