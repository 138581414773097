import React from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogTitle, Slide} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {Fonts} from 'shared/constants/AppEnums';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const DialogConfirmation = ({
  sxStyle,
  maxWidth = 'sm',
  isOpenDialog,
  confirmTextButton = 'Ya',
  cancelTextButton = 'Tidak',
  text,
  isLoading = false,
  onApprove = () => {},
  onReject = () => {},
}) => {
  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
        },
        '& .MuiDialogContent-root': {
          overflowY: 'hidden',
          paddingLeft: 0,
          paddingRight: 0,
        },
        ...sxStyle,
      }}
      maxWidth={maxWidth}
      TransitionComponent={Transition}
      open={isOpenDialog}
      onClose={onReject}
    >
      <DialogTitle
        sx={{
          fontSize: 14,
          fontWeight: Fonts.MEDIUM,
          background: '#F3FDFF',
          color: '#2155CD',
          textTransform: 'uppercase',
        }}
        id='app-dialog-title'
      >
        Konfirmasi
        <IconButton
          aria-label='close'
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: 'grey.500',
          }}
          onClick={onReject}
          size='large'
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Box sx={{px: 6, py: 6}}>
          <Typography>{text}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack spacing={4} direction='row'>
          <Button color='error' variant='contained' onClick={onReject}>
            {cancelTextButton}
          </Button>
          <LoadingButton
            color='primary'
            variant='contained'
            loading={isLoading}
            type='button'
            onClick={onApprove}
          >
            {confirmTextButton}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

DialogConfirmation.propTypes = {
  sxStyle: PropTypes.any,
  maxWidth: PropTypes.any,
  confirmTextButton: PropTypes.string,
  cancelTextButton: PropTypes.string,
  isOpenDialog: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  onApprove: PropTypes.func,
  onReject: PropTypes.func,
};

export default DialogConfirmation;
